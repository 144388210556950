.project-section-background {
    background-image: url('./img/projectBg.png');
}

.project-section-title {
    color: #fff;
}

.projects {
    top: 3rem;
    color: rgb(136, 136, 136);
    font-size: 14px;
    font-family: Poppins, sans-serif;
    letter-spacing: 0.35px;
}

.projects .project-item {
    padding-top: 3rem ;
}

.projects .project-item ul li {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.projects .project-sm-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.projects .project-title {
    font-size: 26px;
    font-weight: 700;
    position: absolute;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    top: 0;
}

.projects .project-item p:hover, 
.projects .project-item ul li:hover,
.projects .project-item ul li em {
    color: #fff;
}

.projects .project-item h4 {
    line-height: 18px;
    font-size: 16px;
    text-transform: uppercase;
    color: #f93534;
    margin-bottom: 10px;
}

.projects .project-item h4:hover {
    color: #fff;
}