.expert-background {
    background-image: url('./img/expertBg.png');
}

.expert-content {
    z-index: 1;
    position: relative;
}

.expert-box-content {
    color: rgb(136, 136, 136);
    font-size: 14px;
    font-family: Poppins, sans-serif;
    letter-spacing: 0.35px;
}

.expert-box-content:hover {
    color: #fff;
}

