.home-background {
    background-image: url('./img/rameshk.jpg')
}

.home-content {
    z-index: 1;
    position: absolute;
}

.home-section-title {
    color: rgb(27, 27, 27);
}

.highlighted-content-title {
    color: #812323;
}

.highlighted-content-title span:hover {
    color: #f93534;
}
